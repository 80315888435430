<template>
    <div>
        <main class="pr-4 md:pr-0">
            <h3 class="font-bold mt-4 text-lg mb-6">Retail Settings</h3>
            <div class="bg-white big-shadow py-4 px-8 rounded-xl">
                <h3 class="font-bold mt-4 text-sm mb-1">Retail Settings</h3>
                <div class="w-6 h-2 rounded-md bg-blue-600"></div>
                <div class="my-16">
                    <div class="mx-auto w-1/2 gap-x-6 big-shadow bg-white py-4 px-6 flex items-center rounded-sm">
                        <div class="flex-initial">
                            <img class="w-8 h-8" src="@/assets/svg/Hardware.svg" alt="">
                        </div>
                        <h6 class="text-base font-bold flex-1">Sub-Category</h6>
                        <div v-if="isEnabled" @click="isEnabled = !isEnabled" class="cursor-pointer w-8 h-8 flex-initial">
                            <img src="@/assets/svg/toggle-right.svg" class="mt-2">
                        </div>
                        <div v-else @click="isEnabled = !isEnabled" class="w-8 h-8 cursor-pointer flex-initial">
                            <img src="@/assets/svg/toggle-left-off.svg" class="mt-2">
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                isEnabled: false
            }
        },
    }
</script>

<style lang="postcss" scoped>

</style>